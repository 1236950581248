var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ReactSelect, { components as defaultComponents, } from 'react-select';
import { ChevronDown } from './ChevronDown.component';
import { Checkbox } from 'components/checkbox/Checkbox.component';
import { Label } from 'components/label/Label.component';
export function MultiDropdownWithoutChips(_a) {
    var defaultValue = _a.defaultValue, name = _a.name, options = _a.options, placeholder = _a.placeholder, disabled = _a.disabled, value = _a.value, onChange = _a.onChange, _b = _a.keyValue, keyValue = _b === void 0 ? '' : _b, _c = _a.closeMenuOnSelect, closeMenuOnSelect = _c === void 0 ? false : _c, onMenuClose = _a.onMenuClose;
    var customStyles = {
        container: function (provided) { return (__assign(__assign({}, provided), { fontFamily: 'var(--font-family)', color: 'var(--black-text-color)', fontSize: 14, lineHeight: '20px' })); },
        valueContainer: function (provided) { return (__assign(__assign({}, provided), { padding: 0, '& input': {
                position: 'absolute'
            }, display: 'flex', maxHeight: 20 })); },
        control: function (provided, status) { return (__assign(__assign({}, provided), { '&:hover': {
                border: '1px solid var(--dropdown-border-color)',
            }, border: '1px solid var(--dropdown-border-color)', boxShadow: 'none', outline: 'none', paddingLeft: 16, paddingRight: 12, paddingTop: 3, paddingBottom: 3, display: 'flex', flexDirection: 'row', gap: 8, minHeight: 32, backgroundColor: status.isDisabled ? 'var(--dropdown-disabled-bg)' : 'var(--dropdown-bg)' })); },
        menu: function (provided) { return (__assign(__assign({}, provided), { boxShadow: 'none', backgroundColor: 'none' })); },
        menuList: function (provided) { return (__assign(__assign({}, provided), { padding: '8px 16px 8px 16px', boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.3)', border: '1px solid #D5D5D5', borderRadius: 4, top: '-9px', background: 'var(--white)', maxHeight: 200 })); },
        option: function (provided, state) { return (__assign(__assign({}, provided), { minHeight: 40, display: 'flex', alignItems: 'center', padding: '8px 0', fontSize: 14, color: '#141414', fontWeight: 400, backgroundColor: state.isFocused ? 'var(--light-grey)' : '', '&:active': {
                backgroundColor: 'var(--light-grey)',
            }, fontFamily: 'var(--font-family)' })); },
        placeholder: function (provided, state) { return (__assign(__assign({}, provided), { color: state.isDisabled ? 'var(--text-disabled-input)' : 'var(--text-grey)', marginLeft: 0, marginRight: 0 })); },
        multiValue: function (base, state) { return (__assign(__assign({}, base), { height: '24px', padding: '0 12px 0 12px', borderRadius: '12px', background: 'var(--paginate-items-bg)', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 0, gap: '6px', color: state.isDisabled ? 'var(--text-disabled-input)' : 'var(--font-family)' })); },
        multiValueLabel: function () { return ({
            color: 'var(--black-text-color)',
            fontSize: '14px',
            fontFamily: 'var(--font-family)',
            fontWeight: '400',
            lineHeight: '20px'
        }); },
    };
    var Option = function (props) {
        var data = props.data;
        var onClickMultiOption = function (e) {
            // @ts-ignore
            // props.selectOption({...data});
            // e.stopPropagation();
            // e.preventDefault();
            if (['INPUT', 'BUTTON'].includes(e.target.tagName)) {
                // @ts-ignore
                props.selectOption(__assign({}, data));
                e.stopPropagation();
                e.preventDefault();
            }
        };
        return (React.createElement("div", null,
            React.createElement(defaultComponents.Option, __assign({}, props),
                React.createElement("div", { style: { display: 'flex', gap: 16 } },
                    React.createElement(Checkbox.Root, { id: props.label, checked: props.isSelected, disabled: props.isDisabled, onCheckedChange: function () { return null; } },
                        React.createElement(Checkbox.Indicator, null)),
                    React.createElement(Label, { htmlFor: props.label, onClick: function (e) { return e.preventDefault(); } }, props.label)))));
    };
    var IndicatorsContainer = function (props) {
        return (React.createElement("div", { className: "indicatorsContainerWrapper ".concat(props.hasValue ? 'indicatorContainerBaseline' : 'indicatorContainerCenter') },
            React.createElement(ChevronDown, { className: "chevronDropdown ".concat(disabled ? 'chevronDisabled' : '') })));
    };
    var MultiValue = function (props) {
        return (React.createElement(React.Fragment, null, props.index === 0 ? props.children : ", ".concat(props.children)));
    };
    var ValueContainer = function (props) {
        return React.createElement(defaultComponents.ValueContainer, __assign({}, props),
            React.createElement("div", { style: { whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis' } }, props.children));
    };
    var components = {
        Option: Option,
        IndicatorsContainer: IndicatorsContainer,
        ValueContainer: ValueContainer,
        MultiValue: MultiValue,
    };
    return (React.createElement(ReactSelect, { key: keyValue, isMulti: true, defaultValue: defaultValue, name: name, options: options, placeholder: placeholder, isDisabled: disabled, onChange: onChange, styles: customStyles, components: components, isSearchable: false, value: value, noOptionsMessage: function () { return 'Brak więcej opcji'; }, hideSelectedOptions: false, 
        // closeMenuOnSelect={closeMenuOnSelect}
        // blurInputOnSelect={false}
        onMenuClose: onMenuClose }));
}
