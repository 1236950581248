export function stockCategoryDictionary(stockCategory) {
    switch (stockCategory) {
        case 'OWN_STOCK':
        case 'SELF':
            return 'Dealera';
        case 'OTHER_DEALERS_STOCK':
        case 'OTHER':
        case 'OTHER_DEALERS':
            return 'Innego dealera';
        case 'IMPORTER_STOCK':
        case 'IMPORTER':
            return 'Importera';
        default:
            return stockCategory;
    }
}
